/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.3.6,
* Autoprefixer: v10.3.1
* Browsers: last 4 version
*/

* {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0;
    margin: 0;
}

#root {
    height: 100vh;
}

@media screen and (orientation:landscape) and (min-width: 600px){

}
.animatedLogo {
    width: 300px;
    position: relative;
    -webkit-animation: logo 2s infinite;
            animation: logo 2s infinite;
  }
  
  @-webkit-keyframes logo {
    0% {width: 700px;}
    50% {width: 500px;}
    100% {width: 700px;}
  }
  
  @keyframes logo {
    0% {width: 700px;}
    50% {width: 500px;}
    100% {width: 700px;}
  }

  @media (max-width:700px) {
    .animatedLogo {
      width: 300px;
      position: relative;
      -webkit-animation: logo 2s infinite;
              animation: logo 2s infinite;
    }
    
    @-webkit-keyframes logo {
      0% {width: 300px;}
      50% {width: 250px;}
      100% {width: 300px;}
    }
    
    @keyframes logo {
      0% {width: 300px;}
      50% {width: 250px;}
      100% {width: 300px;}
    }
  }